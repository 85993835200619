<template>
  <div class="create-post">
    <BlogCoverPreview v-if="showPreview" :blogCoverPhoto="blogPhotoFileURL" @closePreview="closePreview"/>
    <Loading v-if="loading"/>
    <div class="container">
      <div :class="{invisible:!error}" class="err-message">
        <p><span>Error:</span>{{this.errorMsg}}</p>
      </div>
      <div class="blog-info">
        <input type="text" placeholder="请输入博客题目" v-model="blogTitle">
        <div class="upload-file">
          <label for="blog-photo">上传博客封面照片</label>
          <input type="file" ref="blogPhoto" id="blog-photo" accept=".png, .jpg, jpeg" @change="fileChange">
          <button class="preview" :class="{'button-inactive':blogPhotoFileURL == null ? true : false}" @click="openPreview">预览图片</button>
          <span>所选图片: {{blogPhotoName}}</span>
        </div>
      </div>
      <div class="editor">
        <vue-editor :editorOptions="editorSettings" v-model="blogHTML" useCustomImageHandler @image-added="imageHandler"></vue-editor>
      </div>
      <div class="blog-actions">
        <button @click="updateBlog">保存</button>
        <router-link class="router-button" :to="{name:'BlogPreview'}">博客预览</router-link>

      </div>
    </div>
  </div>
</template>

<script>
import BlogCoverPreview from '../components/BlogCoverPreview.vue';
import Quill from 'quill';
//const Image = Quill.import("formats/image")
window.Quill = Quill;
const ImageResize = require("quill-image-resize-module").default;
Quill.register('modules/imageResize',ImageResize)
import Loading from "../components/Loading"
export default {
  components:{
    BlogCoverPreview,
    Loading
  },
  name:'BlogEdit',
  data(){
    return{
      coverPhotoFile:null,
      error:null,
      errorMsg:null,
      loading:null,
      showPreview:false,
      currentBlog:null,
      editorSettings:{
        modules:{
          imageResize:{},
        }
      }
    }
  },
  mounted(){
    const blogs = this.$store.getters.getAllBlogs;
    if(blogs){
        this.currentBlog = blogs.find(blog => blog.id == this.$route.params.blogid);
        this.$store.commit("updateBlogTitle",this.currentBlog.blogTitle);
        this.$store.commit("updateBlogPhotoFileURL",this.currentBlog.blogCoverPhotoPath);
        this.$store.commit("updateBlogHTML",this.currentBlog.blogHTML);
        this.$store.commit("updateBlogPhotoName",this.currentBlog.blogCoverPhotoName);
    }
  },
  computed:{
    blogTitle:{
      get(){
        return this.$store.state.blog.blogTitle;
      },
      set(payload){
        this.$store.commit('updateBlogTitle',payload)
      }
    },
    blogPhotoName:{
      get(){
        return this.$store.state.blog.blogPhotoName;
      },
      set(payload){
        this.$store.commit('updateBlogPhotoName',payload);
      }
    },
    blogPhotoFileURL:{
      get(){
        return this.$store.state.blog.blogPhotoFileURL;
      },
      set(payload){
        this.$store.commit('updateBlogPhotoFileURL',payload)
      }
    },
    blogHTML:{
      get(){
        return this.$store.state.blog.blogHTML;
      },
      set(payload){
        this.$store.commit('updateBlogHTML',payload)
      }
    }
  },
  methods:{
    async fileChange(){
      this.coverPhotoFile = this.$refs.blogPhoto.files[0];
      this.blogPhotoName = this.coverPhotoFile.name;

      console.log(this.coverPhotoFile);
      console.log(this.blogPhotoName);

      //上传图片
      const fd = new FormData();
      fd.append("image",this.coverPhotoFile,this.blogPhotoName)
      
      this.loading = true;
      await this.$store.dispatch('uploadCoverPhoto',fd)
      .then(()=>{
        this.error = false;
        this.errrorMsg = "";
        this.loading = false;
      })
      .catch((err)=>{
        this.error = true;
        this.errorMsg = err.message;
        console.log(this.errorMsg);
        this.loading = false;
      })
    },
    openPreview(){
      console.log('open preview')
      console.log(this.blogPhotoName)
      if(this.blogPhotoName){
        this.showPreview = true;
        console.log(this.showPreview)
      }
    },
    closePreview(){
      console.log("close")
      this.showPreview = false;
    },
    async updateBlog(){
      this.loading = true;
      await this.$store.dispatch('updateBlog',{
        id:this.currentBlog.id.toString(),
        blogTitle: this.blogTitle,
        blogHTML: this.blogHTML,
        blogCoverPhotoPath: this.blogPhotoFileURL,
        blogCoverPhotoName:this.blogPhotoName,
        creator: this.$store.state.auth.userName,
        creatorId: this.$store.state.auth.userId+"",
        createTime: new Date().toDateString(),
      })
      .then(()=>{
        this.error = false;
        this.errrorMsg = "";
        this.loading = false;
        this.$store.dispatch('loadBlogs');
        this.$store.commit('clearBlog');
        this.$router.push({name:'Blogs'});
      })
      .catch((err)=>{
        this.error = true;
        this.errorMsg = err.message;
        console.log(this.errorMsg);
        this.loading = false;
      })

    },
    async imageHandler(file,Editor,cursorLocation,resetUploader){
      //上传图片
      const fd = new FormData();
      fd.append("image",file,file.name);

      await this.$store.dispatch('uploadPhoto',fd)
      .then((res)=>{
        console.log(res)
        var photoURL = res
        Editor.insertEmbed(cursorLocation,"image",photoURL)
        resetUploader()
      })
      .catch((err)=>{
        this.error = true;
        this.errorMsg = err.message;
        console.log(this.errorMsg);
        this.loading = false;
      })
    }
  },
}
</script>

<style lang="scss">
.create-post {
  position: relative;
  height: 100%;
  button {
    margin-top: 0;
  }
  .router-button {
    text-decoration: none;
    color: #fff;
  }
  label,
  button,
  .router-button {
    transition: 0.5s ease-in-out all;
    align-self: center;
    font-size: 14px;
    cursor: pointer;
    border-radius: 20px;
    padding: 12px 24px;
    color: #fff;
    background-color: #303030;
    text-decoration: none;
    &:hover {
      background-color: rgba(48, 48, 48, 0.7);
    }
  }
  .container {
    position: relative;
    height: 100%;
    padding: 10px 25px 60px;
  }
  // error styling
  .invisible {
    opacity: 0 !important;
  }
  .err-message {
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    color: #fff;
    margin-bottom: 10px;
    background-color: #303030;
    opacity: 1;
    transition: 0.5s ease all;
    p {
      font-size: 14px;
    }
    span {
      font-weight: 600;
    }
  }
  .blog-info {
    display: flex;
    margin-bottom: 32px;
    input:nth-child(1) {
      min-width: 300px;
    }
    input {
      transition: 0.5s ease-in-out all;
      padding: 10px 4px;
      border: none;
      border-bottom: 1px solid #303030;
      &:focus {
        outline: none;
        box-shadow: 0 1px 0 0 #303030;
      }
    }
    .upload-file {
      flex: 1;
      margin-left: 16px;
      position: relative;
      display: flex;
      input {
        display: none;
      }
      .preview {
        margin-left: 16px;
        text-transform: initial;
      }
      span {
        font-size: 12px;
        margin-left: 16px;
        align-self: center;
      }
    }
  }
  .editor {
    height: 60vh;
    display: flex;
    flex-direction: column;
    .quillWrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    .ql-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: scroll;
    }
    .ql-editor {
      padding: 20px 16px 30px;
    }
  }
  .blog-actions {
    margin-top: 32px;
    button {
      margin-right: 16px;
    }
  }
}
</style>